import { alertConstants } from '../_constants'
import uniqid from 'uniqid'

export const alertActions = {
    success,
    error,
    remove,
    clear
}

function success(message) {
    return setAlert(alertConstants.SUCCESS, message)
}

function error(message) {
    return setAlert(alertConstants.ERROR, message)
}

function setAlert(type, message) {
    return (dispatch) => {
        const alertId = uniqid();
        setTimeout(() => {
            dispatch(hide(alertId))
        }, 4000);
        setTimeout(() => {
           dispatch(remove(alertId))
        }, 5000);
        dispatch({type, message, alertId})
    }
}

function remove(alertId) {
    return {type: alertConstants.REMOVE, alertId}
}

function clear() {
    return {type: alertConstants.CLEAR}
}

function hide(alertId) {
    return {type: alertConstants.HIDE, alertId}
}