import React, { useState, useEffect } from 'react';
import { api } from '../../_helpers';
import { useParams, Link } from 'react-router-dom';
import { Loading } from '../../_components/UI';
import Breadcrumb from '../../_components/Breadcrumb';
import mswordIcon from '../../assets/msword.png';

export default () => {
    const [ course, setCourse ] = useState({});
    const [ isLoading, setLoading ] = useState(false);
    const [ finished, setFinished ] = useState({done: 0, total: 0, elements: []});
    const localParams = useParams();

    useEffect(() => {
        setLoading(true);
        api({
            method: 'get',
            action: `course/learn/${localParams.courseId}`,
            _then: (res) => {
                let courseNew = res.data.course;
                let quizCount = 0;
                courseNew.topics.map((topic, index) => {
                    if (topic.quiz) {
                        quizCount += 1;
                        if (res.data.finished.includes(topic.quiz._id)){
                            courseNew.topics[index].quiz.finished = true;
                        }
                    }
                });
                setCourse(courseNew);
                setFinished({done: res.data.finished.length, total: quizCount, elements: res.data.finished});
            },
            _catch: err => {
                console.log(err);
            },
            _finally: () => {
                setLoading(false);
            }
        })
    }, []);

    let Render = (
        <React.Fragment>
            <h2>Загрузка...</h2>
            <Loading/>
        </React.Fragment>
    );

    if (!isLoading) {
        let topicsRender;
        if (course.topics) {
            topicsRender = course.topics.map((topic, topicIndex) => {
                let lessonsRender;
                if (topic.lessons) {
                    lessonsRender = topic.lessons.map((lesson, lessonIndex) => {
                        return (
                            <li className={'list-group-item'} key={lessonIndex}>
                                {topicIndex+1}.{lessonIndex+1}.&nbsp;
                                <img src={mswordIcon} width={20} height={20}/>&nbsp;
                                <a href={`/api/file/get/${lesson.doc}`}>
                                    {lesson.title}
                                </a>
                            </li>
                        )
                    })
                }
                let quizRender;
                if (topic.quiz) {
                    let passed = finished.elements.includes(topic.quiz._id);
                    quizRender = (
                        <li className={
                            'list-group-item'+((passed)?' list-group-item-success':' list-group-item-secondary')
                        }>
                            <Link to={`/quiz/${topic.quiz._id}`}>
                                <strong>{topic.quiz.title}</strong>
                                &nbsp;({(passed)?'Пройден':'Не пройден'})
                            </Link>
                        </li>
                    )
                }

                return (
                    <ul className={'list-group'} key={topicIndex} style={{margin: '20px auto',}}>
                        <li className={'list-group-item list-group-item-primary'}>
                            <strong>{topicIndex+1}. {topic.title}</strong>
                        </li>
                        {lessonsRender}
                        {quizRender}
                    </ul>
                )
            })
        }

        Render = (
            <React.Fragment>
                <Breadcrumb links={[
                    {
                        title: 'Главная',
                        path: '/'
                    },
                    {
                        title: course.title,
                        active: true,
                    }
                ]}/>
                <h4>Завершено тестов: {finished.done} из {finished.total}</h4>
                <p>{course.description}</p>
                {topicsRender}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {Render}
        </React.Fragment>
    );
}