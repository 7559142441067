import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { api } from '../../_helpers/';
import {alertActions} from "../../_actions";
import {Button} from "../../_components/UI";

export default function() {
    const [ courses, setCourses ] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        api({
            method: 'get',
            action: 'course/list',
            _then: res => {
                setCourses(res.data);
            }
        })
    }, []);

    const removeCourse = (_id, index) => {
        let ok = window.confirm('Вы уверены?');
        if (ok) {
            api({
                method: 'post',
                action: 'course/remove',
                data: {
                    _id,
                    _level: 'course',
                },
                _then: () => {
                    // console.log(index);
                    dispatch(alertActions.success('Курс успешно удален'));
                    courses.splice(index, 1);
                    setCourses([...courses]);
                }
            })
        };
    }

    const coursesRows = courses.map((course, index) => (
        <tr key={course._id}>
            <td><Link to={`/admin/courses/edit/${course._id}`}>{course.title}</Link></td>
            <td><Button className={'btn-outline-secondary'} onClick={() => removeCourse(course._id, index)}>Удалить</Button></td>
        </tr>
    ))

    return (
        <React.Fragment>
            <div className={`d-flex justify-content-between align-items-center`}>
                <h1>Учебные курсы</h1>
                <div><Link className={`btn btn-primary`} to={`/admin/courses/create`}>+ Создать курс</Link></div>
            </div>
            <table className={'table'}>
                <thead>
                <tr>
                    <th>Название</th>
                    <th>Действия</th>
                </tr>
                </thead>
                <tbody>
                {coursesRows}
                </tbody>
            </table>
        </React.Fragment>
    )
}