require('./date.format');

module.exports.unixToString = function(unixTime) {
    let dateString = new Date(unixTime).format('Y-m-d');
    dateString += 'T';
    dateString += new Date(unixTime).format('H:i');
    return dateString;
}

module.exports.stringToUnix = function(stringDate) {
    let unix = new Date(stringDate).getTime();
    return unix/1000;
}

module.exports.unixToShortString = function(unixTime) {
    let dateString = new Date(unixTime).format('d.m.Y');
    return dateString;
}