import React from 'react'
import { alertActions } from '../../_actions'
import { useDispatch } from 'react-redux'
import styles from './styles.module.scss'

const Alert = ({type, message, alertId, hided}) => {
    const dispatch = useDispatch();

    const cls = {
        'error': 'alert-danger',
        'success': 'alert-success',
    };

    if (type) {
        return (
            <div className={`alert alert-dismissible fade show ${cls[type]} ${hided?styles.hidedAlert:''}`}>
                {message}
                <button type="button" className="close" onClick={() => dispatch(alertActions.remove(alertId))}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        )
    } else {
        return null
    }
};

export default Alert

/* 
Component Helpers
*/

// export function helpers () {
//     const dispatch = useDispatch()
//     return {
//         successAlert: (message) => dispatch(alertActions.success(message)),
//         errorAlert: (message) => dispatch(alertActions.error(message)),
//         clearAlert: () => dispatch(alertActions.clear()),
//     }
// }