import React from 'react'
import {Link, NavLink} from "react-router-dom"
import styles from './styles.module.scss'
import Protected from "../../_hoc/Protected";
import LogoImg from '../../assets/logo.png';

export default function TopNav () {
    return (
        <nav className={`navbar navbar-light `}>
            <div className={'container d-flex justify-content-between'}>
                <Link className={`navbar-brand d-flex align-items-center`} to="/">
                    <img src={LogoImg} width={32} height={32}/>&nbsp;
                    Профессия 21 век
                </Link>
                {/*<input className={`${styles.formControl} ${styles.formControlDark} form-control form-control-dark w-100`} type="text" placeholder="Search" aria-label="Search" />*/}
                <div className="nav d-flex justify-content-between" id="navbarSupportedContent">
                    <ul className="nav">
                        <Protected permission={'user.logout'}>
                            <li className="nav-item">
                                <Link to={`/`} className={'nav-link'}>Главная</Link>
                            </li>
                        </Protected>
                        <Protected permission={'user.list'}>
                            <li className="nav-item">
                                <Link to={`/admin/users`} className={'nav-link'}>Пользователи</Link>
                            </li>
                        </Protected>
                        <Protected permission={'course.list'}>
                            <li className="nav-item">
                                <Link to={`/admin/courses`} className={'nav-link'}>Учебные курсы</Link>
                            </li>
                        </Protected>
                        <Protected permission={'quiz.list'}>
                            <li className="nav-item">
                                <Link to={`/admin/quizes`} className={'nav-link'}>Тесты</Link>
                            </li>
                        </Protected>
                        <Protected permission={'user.login'}>
                            <li className="nav-item">
                                <Link to={`/user/login`}  className={'nav-link'}>Авторизация</Link>
                            </li>
                        </Protected>
                        <Protected permission={'user.logout'}>
                            <li className="nav-item">
                                <Link to={`/user/logout`}  className={'nav-link'}>Выход</Link>
                            </li>
                        </Protected>
                    </ul>
                </div>
            </div>
        </nav>
    )
}