import React, { Component } from 'react'

import { Provider } from 'react-redux'
import Initial from '../Initial';
import { store } from '../../_helpers'

import Router from "../Router"

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Initial>
                    <Router />
                </Initial>
            </Provider>
        );
    }
}

export default App;