import React from 'react'

import AlertList from '../../_components/AlertList'
import TopNav from "../../_components/TopNav"
import Sidebar from "../../_components/Sidebar"

const Layout = (props) => {
    return (
        <React.Fragment>
            <TopNav/>
            <div className='container-fluid'>
                <main role='main' className={`ml-sm-auto pt-3 px-4`} style={{marginBottom: '250px'}}>
                    <AlertList/>
                    <div className={`container`}>
                        {props.children}
                    </div>
                </main>
            </div>
        </React.Fragment>
    )
}

export default Layout