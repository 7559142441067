import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import {Select, Button, Input} from "../../_components/UI";
import { useForm } from "react-hook-form";
import { alertActions } from "../../_actions";
import {api} from "../../_helpers";
import { unixToString, stringToUnix } from '../../_helpers';
import CourseAccessList from "./CourseAccessList";
import uniqid from 'uniqid';

export default function({userId, coursesDefault = []}) {
    const [ courses, setCourses ] = useState(coursesDefault);
    const [ coursesAvailable, setCoursesAvailable ] = useState({});
    const [ isSubmitting, setSubmitting ] = useState(false);
    const dispatch = useDispatch();

    const tillOffset = 60*60*24*30*6*1000;
    const defaultTill = unixToString(Date.now() + tillOffset);
    const { register, handleSubmit, errors, setValue } = useForm({
        defaultValues: {
            till: defaultTill,
        }
    });
    const form = {
        course: {
            name: 'course',
            label: 'Курс',
            help: 'Выберите курс для назначения доступа',
            register: register({
                required: 'Выбор курса обязателен',
            }),
            options: coursesAvailable,
        },
        till: {
            name: 'till',
            label: 'Доступ до',
            register: register({
                required: 'Ограничение обязательно',
            }),
        }
    }

    Object.keys(form).forEach(key => {
        form[key].error = errors[key];
    });

    useEffect(() => {
        // console.log(new Date().getTime());
        api({
            method: 'get',
            action: 'course/list',
            _then: (res) => {
                const av = {};
                res.data.forEach((course) => {
                    av[course._id] = course.title;
                })
                setCoursesAvailable(av);
            }
        })
    }, []);

    const onSubmitCourseAccess = (data) => {
        setSubmitting(true);
        const dataSend = {
            _id: userId,
            courseAccess: {
                course: data.course,
                till: stringToUnix(data.till),
            }
        };
        api({
            method: 'post',
            action: 'user/courseAccessSet',
            data: dataSend,
            _then: (res) => {
                dispatch(alertActions.success(`Доступ к курсу успешно создан/обновлен`));
                setCourses([...res.data.courses]);
                // courses.push({course: dataSend.courseAccess.course, till: dataSend.courseAccess.till});
            },
            _catch: (err) => {
                dispatch(alertActions.error('Ошибка при добавлении доступа к курсу'));
                // console.log(err);
            },
            _finally: () => {
                setSubmitting(false);
            }
        })
    }

    return (
        <div className={'row'}>
            <div className={'col-md-6'}>
                <form onSubmit={handleSubmit(onSubmitCourseAccess)} className={'form-row'}>
                    <Select {...form.course} width={12}/>
                    <Input {...form.till} type={'datetime-local'} width={12}/>
                    <Button loading={isSubmitting} type={'submit'}>Предоставить доступ</Button>
                </form>
            </div>
            <div className={'col-md-6'}>
                <CourseAccessList courses={courses} coursesAvailable={coursesAvailable} rebuild={uniqid()} user={userId}/>
            </div>
        </div>

    );
}