import React from 'react';
import uniqid from 'uniqid';
import {Link} from "react-router-dom";

export default ({links=[]}) => {

    const Links = links.map(link => {
        if (link.active) {
            return <li className={'breadcrumb-item active'} key={uniqid()}>{link.title}</li>;
        } else {
            return <li className={'breadcrumb-item'} key={uniqid()}><Link to={link.path}>{link.title}</Link></li>;
        }

    })

    return (
        <nav aria-label="breadcrumb">
            <ol className = "breadcrumb">
                {Links}
            </ol>
        </nav>
    )
}