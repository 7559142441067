import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../_helpers/';
import { Button } from '../../_components/UI';
import { useDispatch } from "react-redux";
import { alertActions } from "../../_actions";

export default function() {
    const [ quizes, setQuizes ] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        api({
            method: 'get',
            action: 'quiz/list',
            _then: res => {
                setQuizes(res.data);
            }
        })
    }, []);

    const removeQuiz = (_id, index) => {
        let ok = window.confirm('Вы уверены?');
        if (ok) {
            api({
                method: 'post',
                action: 'quiz/remove',
                data: {
                    _id,
                    _level: 'quiz',
                },
                _then: () => {
                    // console.log(index);
                    dispatch(alertActions.success('Тест успешно удален'));
                    quizes.splice(index, 1);
                    setQuizes([...quizes]);
                }
            })
        };
    }

    const quizesRows = quizes.map((quiz, index) => (
        <tr key={quiz._id}>
            <td><Link to={`/admin/quizes/edit/${quiz._id}`}>{quiz.title}</Link></td>
            <td><Button className={'btn-outline-secondary'} onClick={() => removeQuiz(quiz._id, index)}>Удалить</Button></td>
        </tr>
    ))

    return (
        <React.Fragment>
            <div className={`d-flex justify-content-between align-items-center`}>
                <h1>Тесты</h1>
                <div><Link className={`btn btn-primary`} to={`/admin/quizes/create`}>+ Создать тест</Link></div>
            </div>
            <table className={'table'}>
                <thead>
                <tr>
                    <th>Название</th>
                    <th>Действия</th>
                </tr>
                </thead>
                <tbody>
                {quizesRows}
                </tbody>
            </table>
        </React.Fragment>
    )
}