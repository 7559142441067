import React, { useState, useEffect } from 'react';
import {useParams, useHistory} from 'react-router-dom';
import { api } from "../../_helpers";
import { useDispatch} from "react-redux";
import {alertActions} from "../../_actions";

export default () => {
    const params = useParams();
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
    const [quiz, setQuiz] = useState({_id: params.quizId});
    const [answers, setAnswers] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        api({
            method: 'get',
            action: 'quiz/get/'+quiz._id,
            _then: res => {
                setQuiz(res.data);
            },
            _catch: err => {
                console.log(err);
            },
            _finally: () => {
                setLoading(false);
            }
        })
    }, [])

    if (isLoading) {
        return (<h2>Загрузка...</h2>);
    }

    const variantCheckHandler = (value, question) => {
        setAnswers({...answers, [question]: value});
    }

    let questionsRender;
    if (quiz.questions) {
        questionsRender = quiz.questions.map((question, questionIndex) => {
            let variantsRender;
            if (question.variants) {
                variantsRender = question.variants.map((variant, variantIndex) => {
                    return (
                        <li
                            className={'list-group-item' + ((answers[question._id]==variant.index)?' active':'')}
                            key={variantIndex}
                            style={{cursor: 'pointer'}}
                            onClick={() => variantCheckHandler(variant.index, question._id)}
                        >
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name={question._id}
                                    id={`${question._id}${variant._id}`}
                                    value={variant.index}
                                    style={{cursor: 'pointer'}}
                                    checked={answers[question._id]==variant.index?true:false}
                                    onChange={() => variantCheckHandler(variant.index, question._id)}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor={`${question._id}${variant._id}`}
                                    style={{cursor: 'pointer'}}
                                >
                                    {variant.title}
                                </label>
                            </div>
                        </li>
                    )
                })
                return (
                    <ul className={'list-group'} style={{margin: '40px auto',}} key={questionIndex}>
                        <li className={'list-group-item list-group-item-primary'}><strong>{question.title}</strong></li>
                        {variantsRender}
                    </ul>
                )
            }
        })
    }

    const passQuiz = () => {
        if (Object.keys(answers).length < quiz.questions.length) {
            return alert('Вы ответили не на все вопросы');
        }
        let mistakes = 0;
        quiz.questions.forEach((question => {
            if (answers[question._id] != question.rightAnswer) {
                mistakes += 1;
            }
        }));
        if (!mistakes) {
            api({
                method: 'post',
                action: 'quiz/finishQuiz',
                data: {
                    quiz: quiz._id,
                },
                _then: res => {
                    dispatch(alertActions.success('Поздравляем! Тест пройдет успешно!'));
                },
                _catch: err => {
                    console.log(err)
                }
            })
        } else {
            dispatch(alertActions.error(`Ошибок: ${mistakes}. Попробуйте еще раз!`));
            setAnswers([]);
        }
    }

    return (
        <React.Fragment>
            <button className={'btn btn-primary'} onClick={() => {history.goBack()}}>{`<`} Вернуться к курсу</button>
            <h2>{quiz.title}</h2>
            {questionsRender}
            {(quiz.questions)?<button
                className={'btn btn-primary'}
                onClick={passQuiz}
                disabled={(Object.keys(answers).length < quiz.questions.length)?true:false}
            >
                Завершить и проверить
            </button>:null}
        </React.Fragment>
    )

}