import React from 'react';

function Checkbox({
                   name,
                   register,
                   id = name,
                   label = null,
                   help = null,
                   error = null,
                   width = 12,
                   value = '',
               }){
    width = width?`col-md-${width}`:null;
    return (
        <div className={`form-group ${width}`}>
            <div className={'form-check'}>
                <input
                    aria-describedby={`${name}Help`}
                    type={'checkbox'}
                    id={id}
                    name={name}
                    ref={register}
                    className={`form-check-input`}
                />
                {label?<label htmlFor={id} className={'form-check-label'}>{label}</label>:null}
                {error?
                    <small id={`${id}Errors`} className={`form-text text-danger font-weight-bold`}>{error.message}</small>
                    : help?
                        <small id={`${id}Help`} className={`form-text text-muted`}>{help}</small>:null
                }
            </div>
        </div>
    )
}

export default Checkbox;