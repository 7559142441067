import React, { useState, useEffect } from "react";
import styles from './styles.module.scss';
import { useForm } from 'react-hook-form';
import { Input, Button, Loading, Checkbox, Select } from '../../_components/UI';
import { api } from '../../_helpers';
import { useDispatch } from 'react-redux';
import { alertActions } from '../../_actions';
import Variant from "./VariantForm";

export default function({question: questionData ={},  quizId}) {
    const [ isSubmitting, setSubmitting ] = useState(false);
    const [ question, setQuestion ] = useState(questionData?questionData:null);
    const [ removed, setRemoved ] = useState(false);
    // const [ isLoading, setLoading ] = useState(courseId?true:false);

    const dispatch = useDispatch();

    const { register, handleSubmit, errors, setValue } = useForm();

    const form = {
        title: {
            name: 'title',
            label: 'Текст вопроса',
            register: register({
                required: 'Текст вопроса обязателен',
                minLength: {
                    value: 5,
                    message: 'Минимально 5 символов',
                }
            }),
        },
        rightAnswer: {
            name: 'rightAnswer',
            label: 'Правильный',
            register: register({
                required: 'Обязательно',
            }),
        },
    }

    Object.keys(form).forEach(key => {
        form[key].error = errors[key];
        form[key].defaultValue = question[key];
    });

    const onSubmit = data => {
        if (isSubmitting) return;
        setSubmitting(true);

        const dataSend = {
            _id: quizId,
            questions: [{
                _id: question._id?question._id:null,
                title: data.title,
                rightAnswer: data.rightAnswer,
            }],
            _level: 'question',
        }
        api({
            method: 'post',
            action: 'quiz/update',
            data: dataSend,
            _then: res => {
                dispatch(alertActions.success(`Вопрос успешно ${question._id?'обновлен':'создан'}.`));
                setQuestion({...res.data.question});
                // console.log(res);
            },
            _catch: err => {
                dispatch(alertActions.error(`Ошибка: ${err}`));
            },
            _finally: () => {
                setSubmitting(false);
            }
        })
    }

    useEffect(() => {
        Object.keys(form).forEach(key => {
            setValue(form[key].name, question[key]);
        });
    }, [])

    const addVariant = () => {
        const variants = question.variants?question.variants:[];
        let index = 0;
        question.variants.map(variant => {
            if (index < variant.index) return index = variant.index;
        });
        variants.push({index: index+1});
        setQuestion({...question, variants});
    }

    const removeQuestion = () => {
        const ok = window.confirm('Вы уверены?');
        if (ok) {
            api({
                method: 'post',
                action: 'quiz/remove',
                data: {
                    _id: quizId,
                    questions: [{
                        _id: question._id,
                    }],
                    _level: 'question',
                },
                _then: () => {
                    // console.log(index);
                    dispatch(alertActions.success('Вопрос успешно удален'));
                    setRemoved(true);
                }
            })
        };
    }

    let ResultRender;

    if (!removed) {
        let Render = (
            <form onSubmit={handleSubmit(onSubmit)} className={`form-row`}>
                <Input { ...form.title } width={7} notitle={true} className={styles.questionTitleFormField}/>
                <Input { ...form.rightAnswer } width={2} notitle={true}/>
                <div className={'form-group btn-group col-md-3'}>
                    <Button
                        type='submit'
                        loading={isSubmitting}
                        ingroup={true}
                    >{question._id?`Сохранить`:`Создать`}
                    </Button>
                    {question._id
                        ?<Button
                            className={'btn-danger'}
                            type='button'
                            ingroup={true}
                            onClick={removeQuestion}
                        >Удалить
                        </Button>
                        :null
                    }
                </div>
            </form>
        )

        let Variants = null;

        if (question._id) {
            Variants = question.variants.map((variant, index) => {
                return (
                    <Variant key={index} quizId={quizId} questionId={question._id} variant={variant}/>
                )
            });
        }

        ResultRender = (
            <div className={`card ${styles.questionFormBlock}`}>
                <div className={'card-body'}>
                    {Render}
                    {Variants}
                    {question._id?<Button onClick={addVariant} className={`btn-outline-primary ${styles.addQuestionButton} btn-block`}>Добавить вариант</Button>:null}
                </div>
            </div>
        )
    } else {
        ResultRender = null;
    }



    return ResultRender;
}