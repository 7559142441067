import { userConstants } from "../_constants";

const anonUser = {
    id: 0,
    accessToken: null,
    refreshToken: null,
    roles: ['anon'],
    perms: ['user.login'],
};

function initialState() {// Todo: async refresh Token initial state
    let state = anonUser;
    if (localStorage.getItem('user')) {
        state = JSON.parse(localStorage.getItem('user'));
    }
    return state;
}

export function user(state= initialState(), action) {
    switch (action.type) {
        case userConstants.LOGIN:
            window.localStorage.setItem('user', JSON.stringify(action.user));
            return {
                ...state,
                ...action.user,
            };
        case userConstants.LOGOUT:
            window.localStorage.removeItem('user');
            return anonUser;
        default:
            return state;
    }
}