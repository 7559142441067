import React, {useEffect, useState} from 'react';
import uniqid from 'uniqid';
import { unixToShortString } from '../../_helpers';
import { useDispatch } from "react-redux";
import { alertActions } from "../../_actions";
import { api } from '../../_helpers';


export default ({courses: coursesDefault=[], coursesAvailable={}, rebuild=0, user}) => {
    const dispatch = useDispatch();
    const [courses, setCourses] = useState(coursesDefault);

    useEffect(() => {
        setCourses(coursesDefault);
    }, [rebuild]);

    const removeCourse = (index) => {
        const dataSend = {
            _id: user,
            courseAccess: {
                _id: index,
            }
        }
        api({
            method: 'post',
            action: 'user/courseAccessRemove',
            data: dataSend,
            _then: (res) => {
                setCourses([...res.data.courses]);
                dispatch(alertActions.success('Доступ к курсу успешно удален'));
            },
            _catch: (err) => {
                dispatch(alertActions.error('Ошибка при удалении доступа'));
            }
        })
    }

    let coursesList = (<li className={'list-group-item'}>Нет курсов</li>);
    if (courses.length) {
        coursesList = courses.map(course => {
            return (<li className={'list-group-item'} key={course._id}>
                <div className={'row'}>
                    <div className={'col-md-8'}>{coursesAvailable[course.course]} - <strong>{unixToShortString(course.till*1000)}</strong></div>
                    <div className={'col-md-4'}><span className={'btn btn-outline-secondary'} onClick={()=>removeCourse(course._id)}>Удалить</span></div>
                </div>


            </li>);
        });
    }

    const CompRender = (
        <ul className={'list-group'}>
            <li key={uniqid()} className={'list-group-item active'}>Доступы пользователя</li>
            {coursesList}
        </ul>
    );
    return (
    <React.Fragment>
        {CompRender}
    </React.Fragment>
    );
}