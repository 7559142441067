import React, { useEffect, useState } from 'react';
import { api } from '../../_helpers';
import { Loading } from '../../_components/UI';
import {Link} from "react-router-dom";
import uniqid from 'uniqid';

export default () => {

    const [ courses, setCourses ] = useState([]);
    const [ finished, setFinished ] = useState([]);
    const [ isLoading, setLoading ] = useState(false);

    useEffect(() => {
        setLoading(true);
        api({
            method: 'get',
            action: 'course/getMy',
            _then: (res) => {
                setCourses(res.data.courses);
                setFinished(res.data.finished);
            },
            _catch: (err) => {
                console.log(err);
            },
            _finally: () => {
                setLoading(false);
            }
        })
    }, []);

    let Render = null;

    if (isLoading) {
        Render = <Loading/>
    } else {
        if (courses) {
            // console.log(courses);
            const coursesList = courses.map(courseAccess => {
                let quizCount = 0, lessonCount = 0;
                courseAccess.course.topics.forEach(topic => {
                    if (topic.quiz) {
                        quizCount += 1;
                    }
                    lessonCount += topic.lessons.length;
                })
                let courseFinished = [];
                courseAccess.course.topics.forEach(topic => {
                    if (finished.includes(topic.quiz)) {
                        courseFinished.push(topic.quiz);
                    }
                })
                return (
                   <ul className={'list-group col-md-6 mb-md-3'} key={uniqid()}>
                       <li className={'list-group-item list-group-item-primary'}>
                           <h6 style={{marginBottom:0,}}>
                               {courseAccess.course.title}
                           </h6>
                       </li>
                       <li className={'list-group-item d-flex justify-content-between align-items-center'}>
                           Тем в курсе:
                           <span className="badge badge-primary badge-pill">{courseAccess.course.topics.length}</span>
                       </li>
                       <li className={'list-group-item d-flex justify-content-between align-items-center'}>
                           Всего уроков:
                           <span className="badge badge-primary badge-pill">{lessonCount}</span>
                       </li>
                       <li className={'list-group-item d-flex justify-content-between align-items-center'}>
                           <strong>Завершено тестов:</strong>
                           <span className="badge badge-primary badge-pill">{courseFinished.length} из {quizCount}</span>
                       </li>
                       <li className={'list-group-item'}>
                           <Link to={`/learn/${courseAccess.course._id}`} className={'btn btn-block btn-outline-success'}>Изучать</Link>
                       </li>
                   </ul>
                );
            });
            Render = (
                <div className={'row'}>
                    {coursesList}
                </div>
            );
        } else {
            Render= '<strong>Нет доступных курсов</strong>';
        }
    }

    return (
        <React.Fragment>
            <h5 style={{margin: '20px 0px',}}>Доступные курсы</h5>
            {Render}
        </React.Fragment>
    )
}