import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useForm } from 'react-hook-form';
import { Input, Button, Loading, Checkbox, Select } from '../../_components/UI';
import { api } from '../../_helpers';
import { useDispatch } from 'react-redux';
import { alertActions } from '../../_actions';
import FileInput from "../../_components/UI/FileInput";

export default function({lesson: lessonData ={},  courseId, topicId, lessonIndex}) {
    const [ isSubmitting, setSubmitting ] = useState(false);
    const [ lesson, setLesson ] = useState(lessonData?lessonData:null);
    const [ removed, setRemoved ] = useState(false);

    const dispatch = useDispatch();

    const { register, handleSubmit, errors, setValue, getValues } = useForm();

    const form = {
        title: {
            name: 'title',
            label: 'Название урока',
            register: register({
                required: 'Название обязательно',
                minLength: {
                    value: 5,
                    message: 'Минимально 5 символов',
                }
            }),
        },
        doc: {
            name: 'doc',
            label: 'Документ',
            register: register({
                required: 'Документ обязателен',
            }),
            defaultValue: lesson.doc,
            setValue,
            getValues,
        },
    }

    Object.keys(form).forEach(key => {
        form[key].error = errors[key];
    });

    const onSubmit = data => {
        if (isSubmitting) return;
        setSubmitting(true);

        const dataSend = {
            _id: courseId,
            topics: [{
                _id: topicId,
                lessons: [{
                    _id: lesson._id?lesson._id:null,
                    title: data.title,
                    doc: data.doc,
                }],
            }],
            _level: 'lesson',
        }
        // console.log(dataSend);
        api({
            method: 'post',
            action: 'course/update',
            data: dataSend,
            _then: res => {
                dispatch(alertActions.success(`Урок успешно ${lesson._id?'обновлен':'создан'}.`));
                setLesson({...res.data.lesson});
            },
            _catch: err => {
                dispatch(alertActions.error(`Ошибка: ${err}`));
            },
            _finally: () => {
                setSubmitting(false);
            }
        })
    }

    useEffect(() => {
        Object.keys(form).forEach(key => {
            setValue(form[key].name, lesson[key]);
        });
    }, [])

    useEffect(() => {
        // console.log(getValues());
    })

    const removeLesson = () => {
        const ok = window.confirm('Вы уверены?');
        if (ok) {
            api({
                method: 'post',
                action: 'course/remove',
                data: {
                    _id: courseId,
                    topics: [{
                        _id: topicId,
                        lessons: [{
                            _id: lesson._id,
                        }]
                    }],
                    _level: 'lesson',
                },
                _then: () => {
                    dispatch(alertActions.success('Урок успешно удален'));
                    setRemoved(true);
                }
            })
        };
    }

    let Render = null;

    if (!removed) {
        Render = (
            <div className={`${styles.lessonFormBLock}`}>
                <form onSubmit={handleSubmit(onSubmit)} className={`form-row`}>
                    {/*<div className={'col-md-1'}><h3>{lessonIndex})&nbsp;</h3></div>*/}
                    <Input { ...form.title } width={6} notitle={true}/>
                    <FileInput { ...form.doc } width={6}/>
                    <div className={'form-group btn-group col-md-4'}>
                        <Button
                            type='submit'
                            loading={isSubmitting}
                            className={'btn-outline-primary'}
                            ingroup={true}
                        >{lesson._id?`Сохранить`:`Создать`}
                        </Button>
                        {lesson._id
                            ?<Button
                                className={'btn-outline-danger'}
                                type='button'
                                ingroup={true}
                                onClick={removeLesson}
                            >Удалить
                            </Button>
                            :null
                        }
                    </div>
                </form>
            </div>
        )
    }


    return Render;
}