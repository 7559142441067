import React, {useState} from 'react';
import styles from './styles.module.scss';
import { Input, Button } from '../../_components/UI';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { alertActions, userActions } from '../../_actions';
import { api } from '../../_helpers';
import { useHistory } from 'react-router-dom';
import LogoImg from '../../assets/logo.png';

export default function(props) {
    const [ isLoading, setLoading ] = useState(false);
    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm();
    const history = useHistory();

    const onSubmit = data => {
        if (isLoading) return;
        setLoading(true);
        api({
            method: 'post',
            action: 'user/login',
            data: {
                email: data.email,
                password: data.password,
            },
            _then: function(res) {
                dispatch(userActions.login(res.data.user));
                if (props.redirectTo) {
                    history.push(props.redirectTo); // Todo: Can't perform a React state update on an unmounted component.
                }
            },
            _catch: err => {
                if (err.response.data.error === 'user not found') {
                    dispatch(alertActions.error('Неверный логин или пароль'));
                } else {
                    dispatch(alertActions.error('При авторизации возникла ошибка'));
                }
            },
            _finally: () => {
                setLoading(false);
            },
        });
    }

    const form = {
        email: {
            name: 'email',
            label: 'Email',
            register: register({
                required: 'Email обязателен',
            }),
        },
        password: {
            name: 'password',
            label: 'Пароль',
            type: 'password',
            register: register({
                required: 'Пароль обязателен',
            }),
        },
    };
    Object.keys(form).forEach(key => {
        form[key].error = errors[key]
    });

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)} className={`form-row ${styles.LoginForm} text-center`}>
                <img src={LogoImg} style={{display:'block', margin:'20px auto'}}/>
                <Input { ...form.email } width={12} notitle={true}/>
                <Input { ...form.password } width={12} notitle={true}/>
                <Button type='submit' loading={isLoading}>Войти</Button>
            </form>
        </React.Fragment>
    )
}