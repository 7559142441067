import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useForm } from 'react-hook-form';
import { Input, Button, Loading, Checkbox, Select } from '../../_components/UI';
import { api } from '../../_helpers';
import { useDispatch } from 'react-redux';
import { alertActions } from '../../_actions';
import FileInput from "../../_components/UI/FileInput";

export default function({variant: variantData ={},  quizId, questionId}) {
    const [ isSubmitting, setSubmitting ] = useState(false);
    const [ variant, setVariant ] = useState(variantData?variantData:null);
    const [ removed, setRemoved ] = useState(false);

    const dispatch = useDispatch();

    const { register, handleSubmit, errors, setValue, getValues } = useForm();

    const form = {
        title: {
            name: 'title',
            label: 'Текст варианта',
            register: register({
                required: 'Текст обязателен',
            }),
        },
        index: {
            name: 'index',
            label: 'Индекс',
            register: register(),
        },
    }

    Object.keys(form).forEach(key => {
        form[key].error = errors[key];
    });

    const onSubmit = data => {
        if (isSubmitting) return;
        setSubmitting(true);

        const dataSend = {
            _id: quizId,
            questions: [{
                _id: questionId,
                variants: [{
                    _id: variant._id?variant._id:null,
                    title: data.title,
                    index: data.index,
                }],
            }],
            _level: 'variant',
        }
        // console.log(dataSend);
        api({
            method: 'post',
            action: 'quiz/update',
            data: dataSend,
            _then: res => {
                dispatch(alertActions.success(`Вариант успешно ${variant._id?'обновлен':'создан'}.`));
                setVariant({...res.data.variant});
            },
            _catch: err => {
                dispatch(alertActions.error(`Ошибка: ${err}`));
            },
            _finally: () => {
                setSubmitting(false);
            }
        })
    }

    useEffect(() => {
        Object.keys(form).forEach(key => {
            setValue(form[key].name, variant[key]);
        });
    }, [])

    useEffect(() => {
        // console.log(getValues());
    })

    const removeVariant = () => {
        const ok = window.confirm('Вы уверены?');
        if (ok) {
            api({
                method: 'post',
                action: 'quiz/remove',
                data: {
                    _id: quizId,
                    questions: [{
                        _id: questionId,
                        variants: [{
                            _id: variant._id,
                        }]
                    }],
                    _level: 'variant',
                },
                _then: () => {
                    dispatch(alertActions.success('Вариант успешно удален'));
                    setRemoved(true);
                }
            })
        };
    }

    let Render = null;
    if (!removed) {
        Render = (
            <div className={`${styles.variantFormBLock}`}>
                <form onSubmit={handleSubmit(onSubmit)} className={`form-row`}>
                    {/*<div className={'col-md-1'}><h3>{lessonIndex})&nbsp;</h3></div>*/}
                    <Input { ...form.index } width={1} notitle={true}/>
                    <Input { ...form.title } width={8} notitle={true}/>
                    <div className={'form-group btn-group col-md-3'}>
                        <Button
                            type='submit'
                            loading={isSubmitting}
                            className={'btn-outline-primary'}
                            ingroup={true}
                        >{variant._id?`Сохранить`:`Создать`}
                        </Button>
                        {variant._id
                            ?<Button
                                className={'btn-outline-danger'}
                                type='button'
                                ingroup={true}
                                onClick={removeVariant}
                            >Удалить
                            </Button>
                            :null
                        }
                    </div>
                </form>
            </div>
        )
    }

    // if (isLoading) {
    //     Render = <Loading/>;
    // }

    return Render;
}