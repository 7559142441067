import React from 'react';

export default function({
                            loading = false,
                            type = 'button',
                            loadingMessage = 'Подождите',
                            className = null,
                            onClick = null,
                            children,
                            width = 12,
                            ingroup = false})
{
    width = width?`col-md-${width}`:null;
    if (!className) {
        className = 'btn btn-primary';
    } else {
        className = `btn ${className}`;
    }
    const disabled = loading?true:false;
    let button =
        <button className = {className} onClick = {onClick} type = {type} disabled={disabled}>
            {/*{loading?<span className='spinner-grow spinner-grow-sm' role="status" aria-hidden="true"/>:children}*/}
            {loading?null:children}
            {loading?`${loadingMessage}...`:null}
        </button>;
    if (!ingroup) {
        button =
            <div className={`form-group ${width}`}>
                {button}
            </div>
    }
    return button;
}