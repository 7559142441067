import { alertConstants } from '../_constants'

export function alert(state = {}, action) {
    switch (action.type) {
        case alertConstants.SUCCESS:
            return {
                ...state,
                [action.alertId]: {
                    type: 'success',
                    message: action.message
                }
            };
        case alertConstants.ERROR:
            return {
                ...state,
                [action.alertId]: {
                    type: 'error',
                    message: action.message
                }
            };
        case alertConstants.REMOVE:
            delete state[action.alertId];
            return {
                ...state,
            };
        case alertConstants.HIDE:
            if (state[action.alertId]) {
                state[action.alertId].hided = true;
            }
            return {
                ...state
            };
        case alertConstants.CLEAR:
            return {};
        default:
            return state
    }
}