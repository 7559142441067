import React from 'react';
import { useSelector } from "react-redux";
import { Route, Redirect } from 'react-router-dom';
import _ from 'lodash';

export default function({
                            layout: Layout,
                            component: Component = null,
                            render: Render = null,
                            children: Children = null,
                            access: Access = true,
                            fallback: Fallback = '/user/login',
                            ...rest
                        }) {
    const user = useSelector(state => state.user);
    return (
        <Route {...rest} render={({ location }) => {
            if (_.isFunction(Access)) {
                Access = Access(); //Todo: call access function with user object
            } else if (_.isString(Access)) {
                Access = user.perms.includes(Access); //Todo: check access by permission string
            }
            if (Access) {
                return (
                    <Layout>
                        {Component ? <Component/> : null}
                        {Render ? <Render/> : null}
                        {Children}
                    </Layout>
                )
            } else {
                if (Fallback) {
                    return (
                        <Redirect
                            to={{
                                pathname: `${Fallback}`,
                                state: { from: location }
                            }}
                        />
                    )
                } else {
                    return null;
                }
            }
        }}/>
    );
}