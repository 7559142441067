import React from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';

import MainLayout from "../MainLayout";
import BackendTest from '../../_pages/BackendTest';
import Dashboard from '../../_pages/Dashboard';
// import EmptyLayout from "../EmptyLayout";
import AppRoute from "../../_components/AppRoute";
import UserLogin from "../../_pages/User/Login";
import UserLogout from "../../_pages/User/Logout";
import UsersList from "../../_pages/User/List";
import UserCreate from "../../_pages/User/CreateEdit";
import CourseList from "../../_pages/Course/List";
import CourseCreate from "../../_pages/Course/CreateEdit";
import QuizCreate from '../../_pages/Quiz/CreateEdit';
import QuizList from '../../_pages/Quiz/List';
import Login from "../../_pages/User/Login";
import Learn from "../../_pages/Course/Learn";
import PassQuiz from "../../_pages/Quiz/Pass";

export default function () {
    return (
        <BrowserRouter>
            <Switch>
                <AppRoute
                    path={`/`}
                    access={'user.logout'}
                    exact
                    layout={MainLayout}
                    component={Dashboard}
                />
                <AppRoute
                    path={`/login`}
                    access={'user.login'}
                    exact
                    layout={MainLayout}
                    component={Login}
                />
                <AppRoute
                    path={`/admin/users`}
                    access={'user.list'}
                    exact
                    layout={MainLayout}
                    component={UsersList}
                />
                <AppRoute
                    path={`/admin/users/create`}
                    access={'user.create'}
                    layout={MainLayout}
                    component={UserCreate}
                />
                <AppRoute
                    path={`/admin/users/edit/:userId`}
                    access={'user.update'}
                    layout={MainLayout}
                    component={UserCreate}
                />
                <AppRoute
                    path={'/user/login'}
                    extend
                    layout={MainLayout}
                    access={'user.login'}
                    fallback={'/'}
                >
                    <UserLogin redirectTo={'/'} />
                </AppRoute>
                <AppRoute
                    path={'/user/logout'}
                    extend
                    layout={MainLayout}
                    component={UserLogout}
                    access={'user.logout'}
                />



                <AppRoute
                    path={`/admin/courses`}
                    access={'course.list'}
                    exact
                    layout={MainLayout}
                    component={CourseList}
                />
                <AppRoute
                    path={`/admin/courses/create`}
                    access={'course.create'}
                    exact
                    layout={MainLayout}
                    component={CourseCreate}
                />
                <AppRoute
                    path={`/admin/courses/edit/:courseId`}
                    access={'course.update'}
                    exact
                    layout={MainLayout}
                    component={CourseCreate}
                />


                <AppRoute
                    path={`/admin/quizes`}
                    access={'quiz.list'}
                    exact
                    layout={MainLayout}
                    component={QuizList}
                />
                <AppRoute
                    path={`/admin/quizes/create`}
                    access={'quiz.create'}
                    exact
                    layout={MainLayout}
                    component={QuizCreate}
                />
                <AppRoute
                    path={`/admin/quizes/edit/:quizId`}
                    access={'quiz.update'}
                    exact
                    layout={MainLayout}
                    component={QuizCreate}
                />

                <AppRoute
                    path={`/learn/:courseId`}
                    access={'course.learn'}
                    exact
                    layout={MainLayout}
                    component={Learn}
                />
                <AppRoute
                    path={`/quiz/:quizId`}
                    access={'course.learn'}
                    exact
                    layout={MainLayout}
                    component={PassQuiz}
                />



                <AppRoute
                    path={'/test'}
                    exact
                    layout={MainLayout}
                    component={BackendTest}
                />

            </Switch>
        </BrowserRouter>
    )
}