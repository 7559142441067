import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../_actions";
import { Loading } from  "../../_components/UI";

export default function(props) {
    const dispatch = useDispatch();
    const [ready, setReady] = useState(false);


    useEffect(() => {
        const initialize = async function() {
            await dispatch(userActions.initiate());
            setReady(true);
        }
        initialize();
    }, []);

    return(
        <React.Fragment>
            {ready?props.children:<Loading/>}
        </React.Fragment>
    );
}
