import React from 'react';
import { useForm } from "react-hook-form";
import FileInput from "../../_components/UI/FileInput";

export default function() {


    const { register, setValue, getValues } = useForm();

    const form = {
        file: {
            name: 'file',
            register: register(),
            setValue,
            getValues,
        }
    }

    return (
        <React.Fragment>
            <FileInput {...form.file}/>
        </React.Fragment>
    )

    /*const { register, handleSubmit, errors, setValue } = useForm();

    const onSubmitHandler = (data) => {
        // console.log(data.file);
        const formData = new FormData();
        formData.append('file', data.file);
        // console.log(formData);
        api({
            method: 'post',
            action: 'file/upload',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            _then: data => {
                console.log(data);
            },
            _catch: err => {
                console.log(err);
            }
        })
    }

    const form = {
        file: {
            name: 'file',
            ref: register(),
        }
    }

    useEffect(() => {
        register({name: 'file'});
    }, []);

    const handleChange = (e) => {
        // console.log(e.target.files);
        setValue('file', e.target.files[0])
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <input type={'file'} onChange={handleChange} />
                <button type='submit' className={`btn btn-primary`} >Test Fetch</button>
            </form>

        </React.Fragment>
    )*/

}