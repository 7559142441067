import React, { useState, useEffect } from "react";
import Courses from './CourseAccessForm';
// import styles from "./styles.module.scss";
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { Input, Button, Loading, Checkbox, Select } from '../../_components/UI';
import { validateEmail } from "../../_helpers";
import { api } from '../../_helpers';
import { useDispatch } from 'react-redux';
import { alertActions } from '../../_actions';
import CourseAccessForm from "./CourseAccessForm";


export default function() {
    const [ isSubmitting, setSubmitting ] = useState(false);
    const [ isLoading, setLoading ] = useState(false);
    const [ roles, setRoles ] = useState({});
    let success = false;
    const dispatch = useDispatch();
    const params = useParams();
    const [ user, setUser ] = useState({_id: params.userId});
    const { register, handleSubmit, errors, setValue } = useForm();

    const onSubmit = data => {
        if (isSubmitting) return;
        setSubmitting(true);
        const dataSend = {
            email: data.email,
            password: data.password,
            fullname: data.fullname,
            blocked: data.blocked,
            roles: [data.roles],
        };
        // console.log(dataSend);
        let ok = true;
        if (dataSend.roles[0] == 'admin') {
            ok = window.confirm('Вы уверены, что хотите добавить создать администратора?');
        }
        if (!ok) {
            return '';
        }
        if (user._id) {
            dataSend._id = user._id;
        }
        api({
            method: 'post',
            action: (user._id)?'user/update':'user/create',
            data: dataSend,
            _then: res => {
                dispatch(alertActions.success(`Пользователь успешно ${user._id?'обновлен':'создан'}.`));
                setUser(res.data);
                // console.log(res.data);
            },
            _catch: err => {
                dispatch(alertActions.error(`При ${user._id?'обновлении':'создании'} пользователя возникла ошибка.`));
            },
            _finally: () => {
                setSubmitting(false);
                if (success) {
                    // history.push('/admin/users');
                }
            }
        });
    };

    const form = {
        fullname: {
            name: 'fullname',
            label: 'ФИО пользователя',
            help: 'Фамилия Имя Отчество',
            register: register({
                required: 'ФИО обязательно',
                minLength: {
                    value: 3,
                    message: 'Минимально 3 символа',
                }
            }),
        },
        email: {
            name: 'email',
            label: 'Email',
            help: 'Электронная почта пользователя',
            register: register({
                required: 'Email обязателен',
                minLength: {
                    value: 3,
                    message: 'Минимально 3 символа',
                },
                validate: value => validateEmail(value) || 'Неверный формат email'
            }),
        },
        password: {
            name: 'password',
            label: 'Пароль',
            help: 'Пароль от 4 символов',
            register: register({
                required: user._id?false:'Пароль обязателен',
                minLength: {
                    value: 4,
                    message: 'Минимально 4 символа',
                }
            }),
        },
        blocked: {
            name: 'blocked',
            label: 'Заблокирован',
            help: 'Заблокированный пользователь сохраняется в базе данных, но не имеет доступа к личному кабинету',
            register: register({
                required: false,
            }),
        },
        roles: {
            name: 'roles',
            label: 'Роль пользователя',
            help: 'От роли пользователя зависит набор разрешенных функций',
            register: register({
                required: 'Выбор роли обязателен',
            }),
            options: roles,
            nullDef: 'Выберите роль',
        }
    };

    Object.keys(form).forEach(key => {
        form[key].error = errors[key];
    });

    useEffect(() => {
        setLoading(true);
        api({
            method: 'get',
            action: 'user/roles',
            _then: (res) => {
                const rolesSet = {};
                Object.keys(res.data.roles).forEach(key => {
                    if (res.data.roles[key].title) {
                        rolesSet[key] = res.data.roles[key].title;
                    }
                });
                setRoles(rolesSet);
            },
            _catch: err => {
                dispatch(alertActions.error('Ошибка загрузки формы'));
            },
            _finally: () => {
                setLoading(false);
            }
        })
    }, []);

    useEffect(() => {
        if (user._id) {
            setLoading(true);
            api({
                method: 'get',
                action: `user/get/${user._id}`,
                _then: data => {
                    setUser(data.data);
                    const userTemp = data.data;
                    Object.keys(form).forEach(key => {
                        setValue(form[key].name, userTemp[key]);
                    });
                },
                _catch: err => {
                    dispatch(alertActions.error('Ошибка загрузки пользователя'));
                },
                _finally: () => {
                    setLoading(false);
                }
            })
        }
    }, []);

    return (
        <React.Fragment>
            <h1>{user._id?'Редактирование':'Создание'} пользователя</h1>
            <form onSubmit={handleSubmit(onSubmit)} className={`form-row`}>
                <Input { ...form.email } width={6}/>
                <Input { ...form.password } width={6}/>
                <Input { ...form.fullname } />
                <Select { ...form.roles } width={6} />
                <Checkbox { ...form.blocked } width={6} />
                <Button type='submit' loading={isSubmitting}>{user._id?`Сохранить`:`Создать`}</Button>
            </form>
            {user.courses?<CourseAccessForm userId={user._id} coursesDefault={user.courses}/>:null}
        </React.Fragment>
    )
}