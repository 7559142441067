import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { api } from '../../_helpers/';
import {alertActions} from "../../_actions";
import {Button} from "../../_components/UI";

export default function() {
    const [ users, setUsers ] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        api({
            method: 'get',
            action: 'user/list',
            _then: res => {
                setUsers(res.data);
            }
        })
    }, []);

    const removeUser = (_id, index) => {
        let ok = window.confirm('Вы уверены?');
        if (ok) {
            api({
                method: 'post',
                action: 'user/remove',
                data: {
                    _id,
                },
                _then: () => {
                    dispatch(alertActions.success('Пользователь успешно удален'));
                    users.splice(index, 1);
                    setUsers([...users]);
                },
                _catch: err => {
                    console.log(err);
                }
            })
        };
    }

    const usersRows = users.map((user, index) => (
        <tr key={user._id}>
            <td><Link to={`/admin/users/edit/${user._id}`}>{user.email}</Link></td>
            <td>{user.fullname}</td>
            <td>{user.roles}</td>
            <td>{user.blocked?'Да':''}</td>
            <td><Button className={'btn-outline-secondary'} onClick={() => removeUser(user._id, index)}>Удалить</Button></td>
        </tr>
    ))
    return (
        <React.Fragment>
            <div className={`d-flex justify-content-between align-items-center`}>
                <h1>Пользователи</h1>
                <div><Link className={`btn btn-primary`} to={`/admin/users/create`}>+ Создать пользователя</Link></div>
            </div>
            <table className={'table'}>
                <thead>
                <tr>
                    <th>Email</th>
                    <th>ФИО</th>
                    <th>Роли</th>
                    <th>Блокировка</th>
                    <th>Действия</th>
                </tr>
                </thead>
                <tbody>
                {usersRows}
                </tbody>
            </table>
        </React.Fragment>
    )
}