import React from 'react';
import MyCourses from '../../_components/MyCourses';
import Hello from '../../_components/Hello';

export default () => {

    return (
        <React.Fragment>
            <Hello/>
            <MyCourses/>
        </React.Fragment>
    )
}