import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {Input, Button, Loading, Checkbox, Select, Textarea} from '../../_components/UI';
import { api } from '../../_helpers';
import { useDispatch } from 'react-redux';
import { alertActions } from '../../_actions';
import Topic from './TopicForm';

export default function() {

    const [ isSubmitting, setSubmitting ] = useState(false);
    const params = useParams();
    const [ course, setCourse ] = useState({_id: params.courseId, topics: []});

    const [ isLoading, setLoading ] = useState(course._id?true:false);

    const dispatch = useDispatch();

    const { register, handleSubmit, errors, setValue } = useForm({
        defaultValues: {
            title: course.title,
        },
    });

    const form = {
        title: {
            name: 'title',
            label: 'Название курса',
            help: 'Отображаемое название учебного курса',
            register: register({
                required: 'Название обязательно',
                minLength: {
                    value: 5,
                    message: 'Минимально 5 символов',
                }
            }),
        },
        description: {
            name: 'description',
            label: 'Описание курса',
            help: 'Текстовое описание курса',
            register: register(),
        }
    }

    Object.keys(form).forEach(key => {
        form[key].error = errors[key];
    });

    const onSubmit = data => {
        if (isSubmitting) return;
        setSubmitting(true);

        const dataSend = {
            title: data.title,
            description: data.description,
            _level: 'course',
        };
        if (course._id) {
            dataSend._id = course._id;
        }
        api({
            method: 'post',
            action: (course._id)?'course/update':'course/create',
            data: dataSend,
            _then: res => {
                dispatch(alertActions.success(`Курс успешно ${course._id?'обновлен':'создан'}.`));
                setCourse(res.data.course);
            },
            _catch: err => {
                dispatch(alertActions.error(`Ошибка: ${err}`));
            },
            _finally: () => {
                setSubmitting(false);
            }
        })
    }

    const addTopic = () => {
        const topics = course.topics;
        topics.push({});
        setCourse({...course, topics});
        // console.log(topics);
    }

    useEffect(() => {
        if (course._id) {
            api({
                method: 'get',
                action: `course/get/${course._id}`,
                _then: res => {
                    setCourse(res.data);
                    // console.log(res.data);
                    Object.keys(form).forEach(key => {
                        setValue(key, res.data[key]);
                    });
                },
                _catch: err => {
                    dispatch(alertActions.error('Ошибка загрузки курса'));
                },
                _finally: () => {
                    setLoading(false);
                }
            })
        }
    }, []);

    const Course = (
        <form onSubmit={handleSubmit(onSubmit)} className={`form-row`}>
            <Input { ...form.title } width={12}/>
            <Textarea{ ...form.description } width={12}/>
            <Button type='submit' loading={isSubmitting} className={'btn-outline-primary'}>{course._id?`Сохранить`:`Создать`}</Button>
        </form>
    )

    let Topics = null;

    if (course._id) {
        Topics = course.topics.map((topic, index) => {
            return (
                <Topic key={index} courseId={course._id} topic={topic}/>
            )
        });
    }


    return (
        <React.Fragment>
            <h1>{course._id?'Редактирование':'Создание'} курса</h1>
            {Course}
            {Topics}
            {course._id?<Button onClick={addTopic} className={`btn-primary ${styles.addTopicButton} btn-block btn-lg`}>Добавить тему</Button>:null}
        </React.Fragment>
    )

}