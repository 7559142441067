import { userConstants } from "../_constants";
import { api } from "../_helpers";

export const userActions = {
    login,
    logout,
    refresh,
    initiate,
}

function login(payload) {
    return dispatch => {
        const user = {
            id: payload.id,
            accessToken: payload.accessToken,
            refreshToken: payload.refreshToken,
            roles: payload.roles,
            perms: payload.perms,
            fullname: payload.fullname,
        }
        const currentTime = Math.floor(Date.now() / 1000);
        const activeTime = payload.accessToken.exp - payload.accessToken.iat;
        user.accessTokenExpiresAt = currentTime + activeTime;
        user.refreshTokenExpiresAt = currentTime + (payload.refreshToken.exp - payload.refreshToken.iat);
        setTimeout(() => {
            dispatch(refresh());
        }, Math.round(activeTime / 2)*1000);
        dispatch({type: userConstants.LOGIN, user});
    }
}

function refresh() {
    return (dispatch, getStore) => {
        const refreshToken = getStore().user.refreshToken;
        if (!refreshToken) return null;
        api({
            method: 'post',
            action: 'user/refresh',
            headers: {
                Authorization: 'Bearer ' + refreshToken.token,
            },
            _then: res => {
                if (res.data.error) {
                    // console.log(res.data.error);
                    dispatch(logout());
                } else {
                    // dispatch(alertActions.success(`Access token refreshed: ${res.data.user.accessToken.token}`));
                    // dispatch(alertActions.success(`Refresh token refreshed: ${res.data.user.refreshToken.token}`));
                    dispatch(login(res.data.user));
                }
            },
            _catch: () => {
                dispatch(logout());
            }
        })
    }
}

function initiate() {
    return (dispatch, getStore) => {
        const user = getStore().user;
        if (user.refreshToken) {
            const currentTime = Math.floor(Date.now() / 1000);
            if (currentTime >= user.refreshTokenExpiresAt) {
                dispatch(logout());
            } else {
                dispatch(refresh());
            }
        }
    }
}

function logout() {
    return {type: userConstants.LOGOUT};
}