import axios from 'axios';
import { store } from './store';
import { alertActions, userActions } from "../_actions";
import config from "../config";

export function api({
                        method = 'post',
                        action,
                        data = {},
                        headers = {},
                        _then = () => {return null},
                        _catch = () => {return null},
                        _finally = () => {return null}
                    }) {

    const basicUrl = (process.env.NODE_ENV == 'production')?config.production_api_url:'http://localhost:3333';

    const user = (store.getState().user)?store.getState().user:{};
    if (user.accessToken && !headers.Authorization) {
        headers = {
            ...headers,
            Authorization: 'Bearer ' + user.accessToken.token
        };
    }
    axios[method](
        basicUrl + '/api/' + action,
        (method==='get'?{headers}:data),
        (method==='get'?null:{headers}))
        .then(data => {
            _then(data)
            // store.dispatch(alertActions.success(`Axios ${method} Query done!`));
        })
        .catch(err => {
            if (err.response && err.response.status === 403) {
                if (err.response.data.error.name === 'TokenExpiredError') {
                    store.dispatch(userActions.logout());
                }
            } else {
                // console.log(err);
                store.dispatch(alertActions.error('При выполнении запроса позника непредвиденная ошибка'));
            }
            _catch(err);
        })
        .finally(() => {
            _finally();
        });
}