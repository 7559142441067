import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../_actions";

export default function() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userActions.logout());
    });

    return (
        <React.Fragment></React.Fragment>
    );
}