import React from 'react';
import uniqid from 'uniqid';

function Select({
                    name,
                    register,
                    id = name,
                    label = null,
                    help = null,
                    error = null,
                    width = 12,
                    options = {},
                    nullDef = null,
               }){
    width = width?`col-md-${width}`:null;
    id = id + uniqid();

    const optionsList = new Array();
    if (nullDef) {
        optionsList.push(<option key='asdsfas123daasfdsdf' value={''}>{nullDef}</option>);
    }
    Object.keys(options).map( key => {
        optionsList.push(<option key={key} value={key}>{options[key]}</option>);
    });
    return (
        <div className={`form-group ${width}`}>
            {label?<label htmlFor={id}>{label}</label>:null}
            <select
                aria-describedby={`${name}Help`}
                id={id}
                name={name}
                ref={register}
                className={`custom-select`}
            >
                {optionsList}
            </select>
            {error?
                <small id={`${id}Errors`} className={`form-text text-danger font-weight-bold`}>{error.message}</small>
                : help?
                    <small id={`${id}Help`} className={`form-text text-muted`}>{help}</small>:null
            }
        </div>
    )
}

export default Select;