import React from 'react';

function Datepicker({ // Todo: Datepicker not ready to use
                   name,
                   register,
                   id = name,
                   label = null,
                   help = null,
                   type = 'text',
                   error = null,
                   width = 12,
                   notitle = false,
                   className = null,
               }){
    width = width?`col-md-${width}`:null;
    return (
        <div className={`form-group ${width}`}>
            {label&&!notitle?<label htmlFor={id}>{label}</label>:null}
            <input
                aria-describedby={`${name}Help`}
                id={id}
                name={name}
                ref={register}
                type={type}
                className={`form-control ${className}`}
                placeholder={notitle?label:null}
            />
            {error?
                <small id={`${id}Errors`} className={`form-text text-danger font-weight-bold`}>{error.message}</small>
                : help?
                    <small id={`${id}Help`} className={`form-text text-muted`}>{help}</small>:null
            }
        </div>
    )
}

export default Datepicker;