import React, { useState, useEffect } from 'react';
import {api} from "../../../_helpers";
import { useDispatch } from 'react-redux';
import { alertActions } from '../../../_actions';
import uniqid from 'uniqid';
import styles from './styles.module.scss';

function FileInput({
                    name,
                    id = name,
                    label = null,
                    help = null,
                    error = null,
                    width = 12,
                    notitle = false,
                    className = null,
                    register,
                    setValue,
                    getValues,
               }){

    id = uniqid() + id;

    const dispatch = useDispatch();

    width = width?`col-md-${width}`:null;

    const [ filename, setFilename ] = useState(null);
    const [ toUpload, setToUpload ] = useState();
    const [ isUploading, setUploading ] = useState(false);

    useEffect(() => {
        const value = getValues(name);
        if (value) {
            setFilename(value);
        }
        // if (defaultValue) {
        //     setFilename(defaultValue);
        // }
    });

    const clearFilenameHandler = () => {
        setFilename(null);
        setValue(name, null, true);
    }

    const submitUploaderHandler = () => {
        const formData = new FormData();
        // console.log(toUpload);
        formData.append('file', toUpload);
        // console.log(formData);
        setUploading(true);
        api({
            method: 'post',
            action: 'file/upload',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            _then: data => {
                if (data.data.success) {
                    // console.log(name);
                    setValue(name, data.data.file, true);
                    // console.log(data.data.file);
                    setFilename(data.data.file);
                } else {
                    dispatch(alertActions.error('Ошибка загрузки файла на сервер.'));
                }
            },
            _catch: err => {
                console.log(err);
            },
            _finally: () => {
                setUploading(false);
            }
        })
    }

    const hiddenStyle = {
        visibility: false,
        width: 0,
        height: 0,
        overflow: 'hidden',
    }

    const inputId = uniqid();

    return (
        <React.Fragment>
            <div className={`form-group ${width}`}>
                <div style={filename?null:hiddenStyle}>
                    <div className="input-group">
                        <input
                            aria-describedby={`${name}Help`}
                            id={id}
                            name={name}
                            ref={register}
                            type={'text'}
                            className={`form-control ${className}`}
                            placeholder={notitle?label:null}
                        />
                        <div className="input-group-append">
                            <span className={'btn btn-outline-secondary'} onClick={clearFilenameHandler}>Изменить</span>
                        </div>
                    </div>
                </div>
                <div style={filename?hiddenStyle:null}>
                    <div className="input-group">
                        <input
                            type={'file'}
                            onChange={(e) => {setToUpload(e.target.files[0])}}
                            id={inputId}
                        />
                        <div className="input-group-append">
                            <label
                                onClick={(e) => submitUploaderHandler(e)}
                                className={`btn btn-outline-secondary`}
                                disabled={!toUpload}
                            >
                                {isUploading?'Подождите...':'Загрузить'}
                            </label>
                        </div>
                    </div>
                </div>
                {error?
                    <small id={`${id}Errors`} className={`form-text text-danger font-weight-bold`}>{error.message}</small>
                    : help?
                        <small id={`${id}Help`} className={`form-text text-muted`}>{help}</small>:null
                }
            </div>
        </React.Fragment>
    )
}

export default FileInput;