import React, { useState, useEffect } from "react";
import styles from './styles.module.scss';
import { useForm } from 'react-hook-form';
import { Input, Button, Loading, Checkbox, Select } from '../../_components/UI';
import { api } from '../../_helpers';
import { useDispatch } from 'react-redux';
import { alertActions } from '../../_actions';
import Lesson from "./LessonForm";

export default function({topic: topicData ={},  courseId}) {
    const [ isSubmitting, setSubmitting ] = useState(false);
    const [ topic, setTopic ] = useState(topicData?topicData:null);
    const [ quizes, setQuizes ] = useState([]);
    const [ removed, setRemoved ] = useState(false);
    // const [ isLoading, setLoading ] = useState(courseId?true:false);

    const dispatch = useDispatch();

    const { register, handleSubmit, errors, setValue, getValues } = useForm();

    const form = {
        title: {
            name: 'title',
            label: 'Название темы',
            register: register({
                required: 'Название обязательно',
                minLength: {
                    value: 5,
                    message: 'Минимально 5 символов',
                }
            }),
        },
        quiz: {
            name: 'quiz',
            register: register(),
            options: quizes,
        },
    }

    Object.keys(form).forEach(key => {
        form[key].error = errors[key];
        form[key].defaultValue = topic[key];
    });

    const onSubmit = data => {
        if (isSubmitting) return;
        setSubmitting(true);

        const dataSend = {
            _id: courseId,
            topics: [{
                _id: topic._id?topic._id:null,
                title: data.title,
                quiz: data.quiz,
            }],
            _level: 'topic',
        }
        api({
            method: 'post',
            action: 'course/update',
            data: dataSend,
            _then: res => {
                dispatch(alertActions.success(`Тема успешно ${topic._id?'обновлена':'создана'}.`));
                setTopic({...res.data.topic});
                // console.log(res);
            },
            _catch: err => {
                dispatch(alertActions.error(`Ошибка: ${err}`));
            },
            _finally: () => {
                setSubmitting(false);
            }
        })
    }

    useEffect(() => {
        api({
            method: 'get',
            action: 'quiz/list',
            _then: res => {
                let quizesList = {};
                res.data.map(quiz => {
                    quizesList[quiz._id] = quiz.title;
                })
                setQuizes(quizesList);
                Object.keys(form).forEach(key => {
                    setValue(form[key].name, topic[key]);
                });
            },
            _catch: err => {
                console.log(err);
            }
        })
    }, [])

    const addLesson = () => {
        const lessons = topic.lessons?topic.lessons:[];
        lessons.push({});
        setTopic({...topic, lessons});
    }

    const removeTopic = () => {
        const ok = window.confirm('Вы уверены?');
        if (ok) {
            api({
                method: 'post',
                action: 'course/remove',
                data: {
                    _id: courseId,
                    topics: [{
                        _id: topic._id,
                    }],
                    _level: 'topic',
                },
                _then: () => {
                    dispatch(alertActions.success('Тема успешно удалена'));
                    setRemoved(true);
                }
            })
        };
    }

    let Render = null;

    if (!removed) {
        let Topic = (
            <form onSubmit={handleSubmit(onSubmit)} className={`form-row`}>
                <Input { ...form.title } width={12} notitle={true} className={styles.topicTitleFormField}/>
                <Select { ...form.quiz } width={9} notitle={true}/>
                <div className={'form-group btn-group col-md-3'}>
                    <Button type='submit' loading={isSubmitting} ingroup={true}>{topic._id?`Сохранить`:`Создать`}</Button>
                    {topic._id
                        ?<Button
                            className={'btn-danger'}
                            type='button'
                            ingroup={true}
                            onClick={removeTopic}
                        >Удалить
                        </Button>
                        :null
                    }
                </div>
            </form>
        )

        let Lessons = null;

        if (topic._id) {
            Lessons = topic.lessons.map((lesson, index) => {
                return (
                    <Lesson key={index} courseId={courseId} topicId={topic._id} lesson={lesson} lessonIndex={index+1}/>
                )
            });
        }

        Render = (
            <div className={`card ${styles.topicFormBlock}`}>
                <div className={'card-body'}>
                    {Topic}
                    {Lessons}
                    {topic._id?<Button onClick={addLesson} className={`btn-outline-primary ${styles.addTopicButton} btn-block`}>Добавить урок</Button>:null}
                </div>
            </div>

        )
    }

    return Render;


}