import React from 'react'
import { useSelector } from 'react-redux'
import Alert from '../Alert'
import styles from './styles.module.scss'

const AlertList = () => {
    const alerts = useSelector(state => state.alert);

    if (alerts) {
        const list = Object.keys(alerts).map(id => {
            return <Alert
                key={id}
                type={alerts[id].type}
                message={alerts[id].message}
                alertId={id}
                hided={alerts[id].hided}
            />
        });
        return (
            <div className={`container fixed-bottom ${styles.alertsList}`}>
                {list}
            </div>
        )
    } else {
        return null
    }
};

export default AlertList